<template>
    <div style="height: 100%;">
        <div class="login-container">
            <div class="login-photo"></div>
            <div class="login-form">
                <div class="login-title">
                    <p class="greetings">教师端登录</p>
                </div>
                <el-form :model="loginForm" :rules="loginRules" hide-required-asterisk ref="loginForm" class="login-content" :label-position="labelPosition">
                    <el-form-item prop="admin_account" label="账号">
                        <el-input v-model="loginForm.admin_account" placeholder="手机号码/用户名" @keyup.enter.native="submitForm('loginForm')"></el-input>
                    </el-form-item>
                    <el-form-item prop="admin_password" label="密码">
                        <el-input v-model="loginForm.admin_password" placeholder="密       码" type="password" show-password @keyup.enter.native="submitForm('loginForm')"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" class="submit-btn" @click="submitForm('loginForm')">登录</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <FooterModule></FooterModule>
    </div>
</template>

<script>
    import FooterModule from 'components/qualityCourseLayout/FooterModule';
    import {Swiper, SwiperSlide} from "vue-awesome-swiper";
    export default {
        data() {
            return {
                labelPosition: 'top',
                loginForm: {
                    admin_account: '',
                    admin_password: ''
                },
                loginRules: {
                    admin_account: [
                        { required: true, message: '请输入用户名', trigger: 'blur' }
                    ],
                    admin_password: [
                        { required: true, message: '请输入密码', trigger: 'blur' }
                    ],
                }
            }
        },
        components: {
            FooterModule
        },
        methods: {
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let formData = new FormData();
                        formData.append('t_account', this.loginForm.admin_account);
                        formData.append('t_password', this.loginForm.admin_password);
                        this.$shttp.axiosPost(this.$api.teacherLogin, formData, (res) => {
                            if (res.code === 200) {
                                localStorage.setItem('sToken', res.data.token);
                                localStorage.setItem('sUsername', res.data.userInfo.t_name);
                                localStorage.setItem('role', res.data.role);
                                localStorage.setItem('sIcon', res.data.userInfo.t_ico);
                                localStorage.setItem('sId', res.data.t_id);
                                localStorage.setItem('ip', res.data.ip);
                                localStorage.setItem('softUrl', res.data.softUrl);
                                // this.$router.push('/myclass');
                                this.$router.push('/qualityCourse/teacher/index');
                            } else {
                                this.$message.warning(res.msg);
                            }
                        }, (err) => {
                            console.log(err);
                        });
                    } else {
                        return false;
                    }
                });
            }
        }
    }
</script>

<style scoped lang="scss">
   ::v-deep .quality-course-container {
        overflow-x: hidden;
    }
    .login-container {
        /*width: 1200px;*/
        /*min-width: 1200px;*/
        margin: 0 auto;
        display: flex;
        min-height: calc(100% - 84px);
        justify-content: center;
        align-items: center;
        position: relative;
        .login-photo {
            position: absolute;
            background: url("../../assets/images/login-bg-teacher.png") no-repeat 0 0;
            background-size:  100% 100%;
            width: 1200px;
            height: 100%;
            max-width: 1200px;
        }
        .login-form {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 392px;
            width: 500px;
            background-color: #fff;
            box-shadow:0 2px 18px 0 rgba(126,126,131,0.2);
            border-radius:4px;
            z-index: 9;
            .login-title {
                text-align: center;
                .greetings {
                    font-size: 28px;
                    margin: 0 0 1px;
                }
            }
            .login-content {
                width: 336px;
                margin: 0 auto;
               .el-form-item:last-child {
                    margin-top: 28px;
                    margin-bottom: 0;
                }
                ::v-deep .el-form-item__label {
                    padding: 0;
                }
                ::v-deep .el-input {
                    .el-input__inner {
                        height: 45px;
                        line-height: 43px;
                        border-radius: 0;
                        padding: 0;
                        background-color: transparent !important;
                        border-bottom: 1px solid rgba(0,123,255,.25);
                        border-left: none;
                        border-right: none;
                        border-top: none;
                        &:focus {
                            color: #495057;
                            background-color: #fff;
                            outline: none;
                            border-bottom: 1px solid #4A90E2;
                            box-shadow: none;
                        }
                    }
                    .el-input__prefix {
                        position: absolute;
                        top: 50%;
                        left: 10px;
                        transform: translateY(-50%);
                        display: flex;
                        align-items: center;
                        .iconfont {
                            font-size: 20px;
                        }
                    }
                }
                .submit-btn {
                    width: 100%;
                    font-size: 16px;
                    height: 45px;
                    border-radius: 0;
                    border: none;
                    background: linear-gradient(to bottom right, #F35552 , #E31D1A);
                }
            }
        }
    }
</style>